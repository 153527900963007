import React from "react"
import AboutUsContent from "../components/AboutUsContent"
import AboutUsHeader from "../components/AboutUsHeader"
import Layout from "../components/Layout"
import { SEO as Seo } from '../components/SEO.js'

export default function AboutUs() {
  return (
    <Layout>
      <Seo 
        title="About Blackfort - Top Software Development Company in the Philippines"
        description="Founded and based in Manila on 2011 with just a handful of people, we grew to be the reliable Software Development company that we are now, with several partners across diverse industries in the Philippines. Constantly evolving along with the ever-changing world of software development and design, we aim to become the valuable business partner of choice."
        url="https://www.blackfort.ph/about-us"
      />
      <AboutUsHeader />
      <AboutUsContent />
    </Layout>
  )
}
