import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { Button } from "./Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  RobotoMedium,
  RobotoNormal2,
  MPMedium,
  MPAccent,
} from "../styles/PageStyles.js"

const AboutUsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
        frontmatter {
          section1 {
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            title
            description
          }
          section2 {
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            title
            description
          }
          banner {
            background {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            title
            description
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const image = getImage(frontmatter.section1.image)
  const image2 = getImage(frontmatter.section2.image)
  const background = getImage(frontmatter.banner.background)

  return (
    <StyledContainer fluid>
      <Content>
        {/* REALIZING IDEAS SECTION */}
        <StyledRow>
          <StyledCol lg={6} data-aos="fade-right" data-aos-delay="200">
            <GatsbyImage image={image} alt="Coding our way to success" />
          </StyledCol>
          <StyledCol lg={6} data-aos="fade-left" data-aos-delay="200">
            <RobotoMedium lineBreak>{frontmatter.section1.title}</RobotoMedium>
            <RobotoNormal2 lineBreak>
              {frontmatter.section1.description}
            </RobotoNormal2>
          </StyledCol>
        </StyledRow>

        {/* OUR CULTURE SECTION */}
        <StyledRow reversed>
          <StyledCol
            lg={6}
            alignRight
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <RobotoMedium lineBreak>{frontmatter.section2.title}</RobotoMedium>
            <RobotoNormal2 lineBreak>
              {frontmatter.section2.description}
            </RobotoNormal2>
          </StyledCol>
          <StyledCol lg={6} data-aos="fade-left" data-aos-delay="200">
            <GatsbyImage image={image2} alt="Our Culture" />
          </StyledCol>
        </StyledRow>
      </Content>

      {/* BOTTOM BANNER SECTION */}
      <div data-aos="zoom-out" data-aos-delay="200">
        <Banner>
          <CenteredDiv>
            <MPMedium style={{ color: "#FFB700" }}>
              {frontmatter.banner.title}
            </MPMedium>
            <MPAccent>{frontmatter.banner.description}</MPAccent>
            <StyledButton primary="true" round="true" to="/project-estimation">
              Get in touch with us
            </StyledButton>
          </CenteredDiv>
          <GatsbyImage
            image={background}
            alt="Banner"
            className="bottomBanner"
          />
        </Banner>
      </div>
    </StyledContainer>
  )
}

export default AboutUsContent

const StyledContainer = styled(Container)`
  padding: 10vh 0 0 0;
`

const Content = styled.div`
  padding: 0 10% 0 10%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 5vw 0 5vw;
  }
`

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
    width: 100%;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
  padding-top: 5vh;
  padding-bottom: 5vh;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 2vh 0 2vh 0;
  }
`

// Bottom Banner Styled Components

const Banner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 10vh;

  & .bottomBanner {
    display: block;
    width: 100%;
    z-index: -1;

    @media screen and (max-width: 768px) {
      height: 50vh;
    }
  }
`

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
  width: 60%;
  font-size: 16px;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

const StyledButton = styled(Button)`
  margin-top: 5vh;
  background: #ff4700 !important;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    color: black !important;
  }
`